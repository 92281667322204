import React from "react";

const HyderabadToBangalore = () => {
    return (
        <>
        <section>
            <p>Here is the page content</p>
        </section>
        </>
    );
};

export default HyderabadToBangalore;
