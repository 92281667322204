import React from "react";

const BangaloreToHyderabad = () => {
    return(
        <>
          <section>
            <p>Here is the page content</p>
        </section>
        </>
    )
}
export default BangaloreToHyderabad;