import React, { useState } from "react";
import tracklines from "../../img/tracklines.png";
import phoneIcon from "../../img/phoneiconwithbg.png";

const BeforeFooter = () => {
  
    const content = {
        heading: "Need a hand to export your bike",
        para:'Our in-house experts know cars, our garages and our drivers inside out.',
        button: '9391202148',
        email: 'email us',
        livechat: 'Live chat',

    };
    const white = {
        color: 'white'
    }
    const minus = {
        marginBottom : "-8px",
        fontSize: "18px"
    }
    return(
        <>
        <section className="outerline">
        <div className="innerline">
        <h3 style={white}>
        {content.heading}
        </h3>
        </div>
        <div>

        </div>
        <div>
            
            <img src={tracklines} alt="" />
            <div className="contact-box"> <div>
              <p style={white}>{content.para}</p>
            </div>
            <div>
                <div className="number-button"><p style={minus}>{content.button} </p> <span ><img src={phoneIcon} ></img></span> </div>
              
            </div>
            </div>
            
        </div>
        </section>
        </>
    )
}
export default  BeforeFooter;