import React from "react";
import { Link } from "react-router-dom";
import Telangana from '../.././img/telangana.png';
import firestService from "../../img/services One.png";
import Dservices from "../../img/delayed services.png";
import phoneservice from "../../img/phone to phoneservices.png";
import tracking from "../../img/tracking.png";

const Features = () => {

  const Heading = [
    {
        heading : "Features of our Transportation Services",
        CTA : "view all"
    }
  ]
const Locations = [
    {
        heading : "Collabrated with",
        CTA : "Official parcel logistics parters with South central railway at Kacheguda"
    }
]
const minus ={
    marginBottom: '0',
}
    return (
        <>
        <section className="features-of-transportation">
            <div className="inner-parent">
               <div className=" top parts">
                 <h3>{Heading[0].heading}</h3>
                 <Link to="/" className="view-all">
                 <p className="text-padding">View All</p>
                 </Link>
               </div>
               <div className=" bottom">
               <div className="cards">
                <div><img src={firestService} alt="" /></div>
                <div><p style={minus}>Door pickup and door delivary as demaded by the customer
                    </p></div>
               </div>
                <div className="cards">
                <div><img src={Dservices} alt="" /></div>
                <div><p style={minus}>Provision of Monetory compensation for Delayed delivery
                    </p></div>
                </div>
                <div className="cards">
                <div><img src={phoneservice} alt="" /></div>
                <div><p style={minus}>Transperant procedure for the compensation in case of any damages during transportation
                    </p></div>
                </div>
                <div className="cards">
                    <div><img src={tracking} alt="" /></div>
                    <div><p style={minus}>Status of transportation will be updated on regular intervals
                    </p></div>
                </div>
                <div className="cards">
                <div><img src={tracking} alt="" /></div>
                    <div><p style={minus}>Status of transportation will be updated on regular intervals
                    </p></div></div> 

               </div>
            </div>
            <div className="display-flex">
            <img src={Telangana} alt="Telangana" className="telangana"/>
                <div>
                    <h3 className="locations">{Locations[0].heading}</h3>
                    <p className="Lpara">{Locations[0].CTA}</p>
                </div>
            </div>
        </section>
        </>
    )
};
export default Features;