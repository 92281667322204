import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const LandingpageForm = () => {
    const [fromLocation, setFromLocation] = useState("");
    const [toLocation, setToLocation] = useState("");

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate the selections
        if (!fromLocation || !toLocation) {
            alert("Please select both From and To locations.");
            return;
        }

        if (fromLocation === toLocation) {
            alert("From and To locations cannot be the same.");
            return;
        }

        // Generate the dynamic URL
        const newURL = `/${fromLocation.toLowerCase()}-to-${toLocation.toLowerCase()}`;

        // Redirect to the new URL
        navigate(newURL);
    };

    return (
        <div className="form-container normal-form">
            <form onSubmit={handleSubmit} className="landing-page-form">
                {/* From Location */}
                <div className="from-group">
                    <select
                        id="fromLocation"
                        className="fromlocation"
                        value={fromLocation}
                        onChange={(e) => setFromLocation(e.target.value)}
                        required
                    >
                        <option value="">From Location</option>
                        <option value="Hyderabad">Hyderabad</option>
                        <option value="Bangalore">Bangalore</option>
                        <option value="Chennai">Chennai</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Pune">Pune</option>
                    </select>
                </div>

                {/* To Location */}
                <div className="to-group">
                    <select
                        id="toLocation"
                         className="tolocation"
                        value={toLocation}
                        onChange={(e) => setToLocation(e.target.value)}
                        required
                    >
                        <option value="">To Location</option>
                        <option value="Hyderabad">Hyderabad</option>
                        <option value="Bangalore">Bangalore</option>
                        <option value="Chennai">Chennai</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Pune">Pune</option>
                    </select>
                </div>

                <button type="submit" className="Landingpage-submission">Find Routes</button>
            </form>
        </div>
    );
};

export default LandingpageForm;




{/* Third Row: Name */}
            {/* <div className="form-group">
                <input
                    type="text"
                    id="name"
                    className="LPName"
                    placeholder="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div> */}
    
            {/* Fourth Row: Phone Number */}
            {/* <div className="form-group">
                <input
                    type="tel"
                    id="phone"
                    placeholder="phone number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                />
            </div> */}
    
            {/* Submit Button */}